import React from 'react'
import ProductDetailModal from './ProductDetailModal'

const imageStely = {
    width: 'auto',
    height: '200px',
};

const cardStyle = {
    width: '20rem',
    align: 'center',
}

type ProductProps = {
    titel: string;
    picture: string;
    description: string;
    link: string;
    shop: string;
    id:string;
    price:string;
}

type ProductState = {

}

export default class productItem extends React.Component<ProductProps, ProductState> {

    render() {

        return (
            <div className={"col p-3 shadow-sm mb-5 bg-body rounded"}>
                <div className="card" style={cardStyle}>
                    <div className={"align-middle"}><img style={imageStely} src={this.props.picture}
                                                         className="card-img-top"
                                                         alt="Logo"></img></div>
                    <div className="card-body">
                        <h5 className="card-title">{this.props.titel}</h5>
                        <div className={"card-body"}>Preis: {this.props.price}</div>
                        <ProductDetailModal modprice={this.props.price} modid={this.props.id} modetitel={this.props.titel} modpicture={this.props.picture} moddescription={this.props.description} modlink={this.props.link} modshop={this.props.shop}/>
                        <a href={this.props.link} target={"_blank"} className="btn btn-primary" rel="noreferrer">gehe zu : {this.props.shop}</a>
                    </div>
                </div>
            </div>
        );
    }
}