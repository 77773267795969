import React from "react";
import logo from '../pic/mstile-310x310.png'

export default class Agb extends React.Component {
    render() {
        return(
            <div className={"container"}>

                <div className={"row"}>
                    <div className="shadow p-3 mb-5 bg-body rounded">
                    <div className={"col"}>
                        <img
                            className={"img-fluid"}
                            src={logo}
                            alt="First slide"
                            height={150}
                            width={150}
                        /> <h1>Allgemeine Geschäftsbedigungen</h1>
                    </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-start"}>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                        <p>Allgemeine Geschäftsbedingungen (im Folgenden „AGB“) der shop37 GmbH (im Folgenden „shop37“):</p>
                        <p>Der digitale shop wird angeboten und betrieben von der shop37 GmbH. Nähere Details und Kontaktdaten zur shop37 GmbH (im Folgenden kurz: shop37) sind im Impressum abrufbar.</p>
                        <p>Shop37 führt keine Bestellung durch, sie vermittelt sie lediglich. Jeder Teilnehmer schließt Verträge nur mit Anbietern. Es gelten die zum Zeitpunkt der Teilnahme und auch der Nutzung unserer Plattform gültigen AGB. Abweichende Bedingungen des Teilnehmers erkennt shop37 nicht an, es sei denn, shop37 hat ausdrücklich schriftlich ihrer Geltung zugestimmt.</p>
                        <p className={"h3"}>VORBEMERKUNG</p>
                        <p>Shop37 bietet allen Nutzern des Portals https://shop37.de  die Möglichkeit, kostenlos und ohne Verpflichtung die Inhalte der Angebote Dritter abzurufen. Shop37 bietet hierneben an, jedem 37. Teilnehmer einer Kaufpreiskategorie, dessen vollen und von ihm bezahlten Kaufpreis eines über shop37 vermittelten Kaufes oder einer so zustande gekommenen Dienstleistung zu schenken. Hierfür ist die Anlage eines Teilnehmerkontos notwendig.</p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <p className={"h3"}>1. SHOP37: TEILNAHME UND MITGLIEDSCHAFT</p>
                        <p className={"h4"}>1.1. Teilnahme </p>
                        <p>Jede natürliche Person mit dem Mindestalter von 16 Jahren ist berechtigt, an Shop 37 teilzunehmen. Die Teilnahme an Shop 37 beschreibt sich wie folgt: mit der Ankunft auf der Seite https://shop37.de oder einer Unterseite hierzu wird der Internetnutzer zum Teilnehmer. Hier kann dieser sich anhand der Suchfunktionen einen Überblick über die Waren und Dienstleistungen aller Anbieter verschaffen, die Shop 37 ihm vermittelt. Diese Teilnahme ist kostenfrei. </p>
                         <p className={"h4"}>1.2 Mitgliedschaft</p>
                        <p>Jeder Teilnehmer kann sich bei Shop 37 als Mitglied registrieren. Hierdurch wird ein Mitgliedskonto eingerichtet. In diesem Mitgliedskonto kann jedes Mitglied seine Aktivitäten und den jeweils aktuellen Datenschutzbericht im Wege einer automatisierten Auskunft nach Art. 15 DSGVO einsehen und auch herunterladen. Das Mitglied hat hier auch die Möglichkeit, die von ihm bestellten Produkte zu bewerten und somit Feedback zu hinterlassen. So kann jedes Mitglied den Shop37-Produktpartnern seine/ihre Meinung mitteilen und den Marken wichtige Verbraucherinformationen geben, die die Entwicklung neuer Produkte beeinflussen können und helfen, neue Produkte zu kreieren.</p>
                        <p>Ein Mitgliedsbeitrag wird nicht erhoben. Das Mitglied sichert zu, dass Shop 37 seine anonymisierten personenbezogenen Daten und sein Konsum- und Bewertungsverhalten von Shop 37 verarbeitet werden dürfen und in der verarbeiteten Form auch im Eigentum von Shop 37 übergeht.</p>
                        <p>Bei der Registrierung werden verschiedene persönliche Daten abgefragt, die Shop 37 benötigt, um die Mitgliedsbetreuung ordnungsgemäß auszuführen. Darüber hinaus können Mitglieder in ihrem Kundenkonto weitere soziodemografische Daten in Form eines Interessenprofils angeben. Die Abgabe dieser Informationen ist freiwillig und dient dazu, dass Shop 37 dem Mitglied personalisierte Produktvorschläge unterbreiten kann.</p>
                        <p>Ein Anspruch auf Mitgliedschaft bei Shop 37 besteht nicht. </p>
                        <p>Mitglieder müssen ihre Zugangsdaten geheim halten und dürfen diese nicht an Dritte weitergeben. Der Shop37.de-Account eines Mitglieds darf ausschließlich vom Mitglied persönlich genutzt werden. Das Mitglied muss Shop 37 unverzüglich in Textform informieren, wenn Anhaltspunkte vorliegen, die den Verdacht nahelegen, dass Dritte den Account des Mitglieds nutzen oder Kenntnis von seinen Zugangsdaten erlangt haben.</p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                        <p className={"h3"}>2. INHALTE VON MITGLIEDERN IM RAHMEN DER PRODUKTBEWERTUNG </p>
                        <p>2.1. Jedes Mitglied versichert, nur solche Inhalte (bspw. Texte) im Rahmen der Produktbewertungen abzugeben, an denen er/sie alle erforderlichen Nutzungsrechte eingeholt hat. Insbesondere dürfen Rechte Dritter (etwa von Personen/Models oder Künstlern) oder Rechtsvorschriften (u.a. Strafgesetze) durch die abgegebenen Inhalte nicht verletzt werden.</p>
                        <p>2.2. Mitglieder, die Inhalte im Rahmen der Produktbewertungen abgeben, verpflichten sich zur Wahrung der im Internet üblichen Umgangsformen. Insbesondere dürfen keine Inhalte (Texte, Bilder, Videos, Dateien etc.) geladen oder verlinkt werden, die (a) Persönlichkeitsrechte oder Schutzrechte Dritter beeinträchtigen können, (b) als obszön, beleidigend, diffamierend, anstößig, gewaltverherrlichend, pornografisch, belästigend, volksverhetzend, ausländerfeindlich, rechtsradikal oder als sonst verwerflich gewertet werden können, (c) Viren, Hacker-Tools oder Spam enthalten oder darstellen. Shop 37 und seine Administratoren sind berechtigt, einzelne Inhalte des Mitglieds oder Teile hiervon zu löschen. Dies gilt insbesondere, wenn die Inhalte gegen gesetzliche Vorschriften oder den 'Guten Umgangston' verstoßen. Shop37 wird während der Aufklärung des Sachverhaltes hierzu, den streitigen Inhalt für Dritte nicht mehr lesbar schalten und darüber hinaus das Mitglied über eine geplante Löschung dessen informieren und diesem damit ausreichend Gehör verschaffen, bevor über eine Löschung und deren Durchführung abschließend entschieden wird. </p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <p className={"h3"}>3. NUTZUNG VON INHALTEN DER MITGLIEDER, WERBUNG</p>
                        <p>3.1. Das Mitglied räumt shop37 übereignet alle Inhalte, die von ihm im Rahmen einer Produktbewertung im Kundenkonto abgegeben wurden und rechtlich geschützt sind (bspw. Texte, Bilder, Videos, Dateien) vorsorglich überträgt er das nicht-exklusive Recht, diese Daten unentgeltlich und beliebig oft sowie zeitlich und räumlich unbeschränkt zu verwenden. Mit eingeschlossen ist – in den genannten Grenzen – insoweit das Recht, die Inhalte zu vervielfältigen, zu verbreiten, auszustellen und öffentlich zugänglich zu machen. Ferner darf shop37 diese Nutzungsrechte – in den vorstehend festgelegten Grenzen – an den jeweiligen Produktpartner weiter lizenzieren.</p>
                        <p>3.2. Zum Zwecke der Werbung für das jeweilige Produkt und/oder den Produktpartner dürfen einzelne Zitate eines Mitglieds mit Nennung dessen Vornamen, Alters und Wohnorts von shop37 und von den Produktpartnern verwendet werden. </p>
                        <p>3.3. Soweit shop37 Inhalte eines Mitglieds wie vorstehend beschrieben verwendet, aber dennoch von Dritten unter Hinweis auf eigene Rechte in Anspruch genommen wird, kann shop37 vom Mitglied für sich selbst, für den Produktpartner und für autorisierte Verwender der Inhalte verlangen, von allen berechtigten Ansprüchen freigestellt zu werden. Dies umfasst auch die Übernahme von angemessenen Kosten einer Rechtsverteidigung gegen die erhobenen Vorwürfe.</p>
                        <p>3.4. Die vorstehenden Rechte verbleiben auch bei Abmeldung des shop37-Mitglieds bei shop37. Teilweise nutzt shop37 auch im gesetzlich zulässigen Rahmen andere Dienstleister, welche uns als Auftragsverarbeiter unterstützen.</p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                        <p className={"h3"}>4. HAFTUNG</p>
                        <p>Die Haftung von shop37 ist beschränkt auf Schäden die von shop37, deren gesetzlichen Vertretern oder Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursacht worden sind. Diese Beschränkung gilt nicht für die Haftung von zugesicherten Eigenschaften und für die Verletzung sog. Kardinalpflichten, d.h. solchen Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung der über shop37.de angebotenen Leistungen überhaupt erst ermöglicht und auf deren Einhaltung das shop37-Mitglied regelmäßig vertrauen darf. Soweit shop37 bei der Verletzung von Kardinalpflichten keine vorsätzliche oder grob fahrlässige Verursachung angelastet werden kann, ist die Schadensersatzhaftung auf den vorhersehbaren, typischerweise eintretenden Schaden begrenzt; dies gilt auch, soweit Ersatz nutzloser Aufwendungen anstelle des Schadensersatzes statt der Leistung verlangt wird. Sämtliche vorstehenden Haftungsbeschränkungen gelten nicht im Falle von Personenschäden oder im Rahmen der Haftung nach dem Produkthaftungsgesetz. Die genannten Haftungsregelungen gelten in gleicher Weise auch für die persönliche Haftung der gesetzlichen Vertreter und Erfüllungsgehilfen von shop37 sowie für die Haftung der jeweiligen shop37-Produktpartner und die persönliche Haftung von deren gesetzlichen Vertretern und Erfüllungsgehilfen.</p>
                        <p>In allen Fällen vertraglicher und außervertraglicher Haftung leistet shop37 Schadensersatz oder Ersatz vergeblicher Aufwendungen nur in dem nachfolgend bestimmten Umfang:</p>
                        <p>
                            <ul>
                                <ol>1.	Shop37 haftet unbegrenzt für Vorsatz und grobe Fahrlässigkeit, bei Verletzungen des Lebens, des Körpers oder der Gesundheit, nach den Vorschriften des Produkthaftungsgesetzes und für ausdrücklich garantierte Eigenschaften.</ol>
                                <ol>2.	Vorbehaltlich Ziff. 1 haftet shop37 bei leichter Fahrlässigkeit nur für die Verletzung wesentlicher Vertragspflichten. Als wesentliche Vertragspflicht gilt eine Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. In diesem Fall ist unsere Haftung der Höhe nach auf solche Schäden begrenzt, die bei der Durchführung des Vertrages typisch und vorhersehbar waren. </ol>
                            </ul>
                        </p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <p className={"h3"}>5. SELBSTVERPFLICHTUNGSKLAUSEL</p>
                            <p>Mit Aufgabe einer Bestellung bei einem Anbieter von alkoholischen Produkten bestätigt der Kunde, das gesetzlich geforderte Mindestalter zum Erwerb alkoholischer Getränke erreicht zu haben.</p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <p className={"h3"}>6. ALTERNATIVE STREITBEILEGUNG GEMÄSS ART. 14 ABS. 1 ODER-VO UND § 36 VSBG</p>
                            <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter https://ec.europa.eu/consumers/odr/ finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind shop37 nicht verpflichtet und nicht bereit.</p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <p className={"h3"}>7. ÄNDERUNG DIESER AGB</p>
                            <p>Shop37 behält sich vor, diese AGB zu ändern oder zu ergänzen. Shop37 wird  dies dem Mitglied in Textform unter der vom Mitglied bei shop37.de angegebenen E-Mail-Adresse mitteilen. Eine Änderung gilt als genehmigt, wenn das shop37-Mitglied (a) nicht innerhalb eines Monats nach dem Versand der Mitteilung kündigt oder aber (b) eine weitere Bestellung tätigt, nachdem ihm die Mitteilung über die Änderung dieser Nutzungsbedingungen zugegangen ist. Shop37 weist in der Mitteilung hierauf noch einmal gesondert hin.</p>
                        </div>
                        <div className="shadow p-3 mb-5 bg-body rounded">
                            <p className={"h3"}>8. SCHLUSSBESTIMMUNGEN</p>
                            <p>8.1 Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts, auch wenn aus dem Ausland bestellt oder in das Ausland geliefert wird. Wenn der Kunde seinen Wohnsitz oder gewöhnlichen Aufenthalt im Ausland hat, ist Vaduz Gerichtsstand für alle Ansprüche im Zusammenhang mit der Bestellung. Shop37 ist berechtigt, auch an dem allgemeinen Gerichtsstand des Kunden zu klagen.</p>
                            <p>Als Gerichtsstand wird Vaduz vereinbart, wenn ein Mitglied a) keinen allgemeinen Gerichtsstand im Inland hat oder b) nach Anmeldung shop37 seinen Wohnsitz oder gewöhnlichen Aufenthaltsort aus dem Geltungsbereich der Deutschen Zivilprozessordnung verlegt oder c) sein Wohnsitz oder gewöhnlicher Aufenthalt zum Zeitpunkt einer Klageerhebung nicht bekannt ist. Diese Nutzungsbedingungen unterliegen dem materiellen deutschen Recht; andere Rechtsregime sind nicht anwendbar, auch nicht als Bestandteil deutschen Rechts.</p>
                            <p>8.2. Zur Zurückbehaltung ist der Kunde nur befugt, sofern die Ansprüche auf dem gleichen Vertragsverhältnis beruhen.</p>
                            <p>8.3 Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, so soll dies die Wirksamkeit der AGB im Übrigen unberührt lassen. Die Parteien werden die unwirksame oder undurchführbare Bestimmung durch eine wirksame Regelung ersetzen, welche dem wirtschaftlichen Zweck der unwirksamen oder undurchführbaren Bestimmung möglichst nahekommt. Das Gleiche gilt im Fall einer Regelungslücke.</p>
                            <p>8.4 Ladungsfähige Anschrift: shop37 GmbH, Herrengasse 11, FL-9490 Vaduz | Vertreten durch: Dr. iur. Eberhard Frohnecke | Handelsregister im Fürstentum Liechtenstein: FL-0002.676-229-6</p>
                            <p className={"fw-bolder"}>Stand: März 2022</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}