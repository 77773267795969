import React from 'react'
import logo from '../pic/mstile-310x310.png'

export default class sogehts extends React.Component {

    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-md-4"}></div>
                    <div className={"col-md-4"}>

                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img
                                        style={{opacity: 0.2}}
                                        src={logo}
                                        alt="erstes slide"
                                        height={310}
                                        width={310}
                                    />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h3>1. Anmelden</h3>
                                        <p>Melden Sie sich jetzt an auf www.Shop37.de</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img
                                        style={{opacity: 0.2}}
                                        src={logo}
                                        alt="zweites slide"
                                        height={310}
                                        width={310}
                                    />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h3>2. Bestellen</h3>
                                        <p>Wählen Sie über Shop37 Ihren Shop und bestellen Sie Ihr Produkt. Ihnen wird
                                            nun eine Zahl zwischen 0 und 36 zugewiesen.</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img
                                        style={{opacity: 0.2}}
                                        src={logo}
                                        alt="drittes slide"
                                        height={310}
                                        width={310}
                                    />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h3>3. Ziehung</h3>
                                        <p>Nach der Bestellung werden zwischen 37 Teilnehmern eine Ziehung stattfinden,
                                            die anonym und unbeeinflusst, online durchgeführt wird</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img
                                        style={{opacity: 0.2}}
                                        src={logo}
                                        alt="viertes slide"
                                        height={310}
                                        width={310}
                                    />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h3>4. Auszahlung</h3>
                                        <p>Wird die Ihnen zugeordnete Zahl gezogen ist Ihr Einkaufswert nach 72 Stunden
                                            auf Ihrem Konto.</p>
                                    </div>

                                </div>
                                <button className="carousel-control-prev" type="button"
                                        data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button"
                                        data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className={"col-md-4"}></div>
                    </div>
                </div>
            </div>
        );
    }
}