import React from 'react'
import logo from "../pic/mstile-310x310.png";

export default class impressum extends React.Component {
    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col align-items-start"}><h1>Impressum</h1></div>
                </div>
                <div className={"row align-items-start"}>
                    <div className={"col-md-2"}> </div>
                    <div className={"col-md-8 align-items-start"} ><b> Shop 37 GmbH</b><br/>
                        Herrengasse 11<br/> FL-9490 Vaduz<br/><br/>
                        <img

                            src={logo}
                            alt="First slide"
                            height={150}
                            width={150}
                        />
                    </div>
                    <div className={"col-md-2"}> </div>
                </div>
            </div>
        );
    }
}