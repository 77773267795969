import React from 'react'
import {ReactCookieProps, withCookies} from "react-cookie";
import {NavLink} from "react-router-dom";
import ProducItme from '../components/productItem'
import logo from "../pic/mstile-310x310.png";
import Product from "../MyClasses/ProductClass";

type ProdOverViewState = {
    isLoaded: boolean;
    items: [Product];
    error: string;
}

class productoverview extends React.Component <ReactCookieProps, ProdOverViewState> {

    constructor(props: ReactCookieProps) {
        super(props);
        this.state = {
            error: "", items: [{timestamp: 'test',
                uudi: 'test',
                id: 'test',
                promotion_ID: 'test',
                shipping_LABEL: 'test',
                shipping_WEIGHT: 'test',
                shipping_LENGTH: 'test',
                shipping_WIDTH: 'test',
                shipping_HEIGHT: 'test',
                min_HANDLING_TIME: 'test',
                max_HANDLING_TIME: 'test',
                program_NAME: 'test',
                program_URL: 'test',
                catalog_NAME: 'test',
                title: 'test',
                link: 'test',
                impression_URL: 'test',
                image_LINK: 'test',
                mobile_LINK: 'test',
                additional_IMAGE_LINK: 'test',
                availability: 'test',
                availability_DATE: 'test',
                expiration_DATE: 'test',
                price: 'test',
                sale_PRICE: 'test',
                sale_PRICE_EFFECTIVE_DATE: 'test',
                unit_PRICING_MEASURE: 'test',
                unit_PRICING_BASE_MEASURE: 'test',
                installment: 'test',
                loyalty_POINTS: 'test',
                google_PRODUCT_CATEGORY: 'test',
                google_PRODUCT_CATEGORY_NAME: 'test',
                product_TYPE: 'test',
                brand: 'test',
                gtin: 'test',
                mpn: 'test',
                identifier_EXISTS: 'test',
                conditions: 'test',
                adult: 'test',
                multipack: 'test',
                is_BUNDLE: 'test',
                energy_EFFICIENCY_CLASS: 'test',
                age_GROUP: 'test',
                color: 'test',
                gender: 'test',
                material: 'test',
                pattern: 'test',
                size: 'test',
                size_TYPE: 'test',
                size_SYSTEM: 'test',
                item_GROUP_ID: 'test',
                custom_LABEL0: 'test',
                custom_LABEL1: 'test',
                custom_LABEL2: 'test',
                custom_LABEL3: 'test',
                custom_LABEL4: 'test',
                last_UPDATED: 'test',
                description: 'test',}],
            isLoaded: false
        };
    }

    isLocal(): boolean {
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "" || window.location.hostname === "codingfarm-dockerserver") {
            return true;
        }
        return false;
    }

    componentDidMount() {
        if (this.isLocal()) {
            fetch("http://codingfarm-dockerserver:8082/shop37-backend/api/product/tops")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            items: result
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: false,
                            error
                        });
                    }
                )
        }
    }

    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/#">Auto</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to="/#">Ford</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Allrad</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className={"row row-cols-auto"}>
                    {this.isLocal() ?
                        this.state.isLoaded && this.state.items.length>0?
                            this.state.items.map(item => (<div>
                                    <ProducItme description={item.description}
                                                link={item.link}
                                                picture={item.image_LINK}
                                                shop={item.brand}
                                                titel={item.title}
                                                id={item.id}
                                                price={item.price}
                                    ></ProducItme>
                                </div>)
                                )
                             : null
                        :
                        <div>
                            <div>
                            <ProducItme id={"1"}
                                        description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme id={"2"}
                                description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"3"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"4"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"5"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"6"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"7"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"8"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"9"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"10"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div>
                            <div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"11"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                            </div><div>
                            <ProducItme description={"Hallo Welt"}
                                        link={"https://www.codingfarm.org"}
                                        picture={logo}
                                        shop={"Avus"}
                                        titel={"Hallo Welt"}
                                        id={"12"}
                                        price={"20.- CHF"}
                            ></ProducItme>
                        </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withCookies(productoverview)