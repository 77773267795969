import React from 'react';
import {NavLink} from "react-router-dom";
import {Cookies, ReactCookieProps, withCookies} from "react-cookie";
import {instanceOf} from 'prop-types';
import Shop37Logo from '../pic/mstile-70x70.png'

class TopMenu extends React.Component<ReactCookieProps, any> {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props: ReactCookieProps) {
        super(props);

        const {cookies} = props;
        this.state = {
            usrprops: cookies?.get('usrprops') || 'gugus'
        };
    }

    render() {
        let check = false;
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "" || window.location.hostname === "codingfarm-dockerserver") {
            check = true;
        }

        return (
            <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/">
                        <img src={Shop37Logo} alt="Shop37Logo" width="60" height="60"></img>
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="true" aria-label="Toggle navigation"/>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="/#"role="button"
                                   data-bs-toggle="dropdown" aria-expanded="false">Über uns</NavLink>
                                <ul className="dropdown-menu" aria-labelledby="ueberunsdropdown">
                                    <li><NavLink className={"dropdown-item"} to="/ueberuns">Über Uns</NavLink></li>
                                    <li><hr className="dropdown-divider"></hr> </li>
                                    <li><NavLink className={"dropdown-item"} to="/agb">AGB</NavLink></li>
                                    <li><NavLink className={"dropdown-item"} to="/Impressum">Impressum</NavLink></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="/#"role="button"
                                         data-bs-toggle="dropdown" aria-expanded="false">So geht's</NavLink>
                                <ul className="dropdown-menu" aria-labelledby="ueberunsdropdown">
                                    <li><NavLink className={"dropdown-item"} to="/sogehts">So geht's</NavLink></li>
                                    <li><hr className="dropdown-divider"></hr> </li>
                                    <li><NavLink className={"dropdown-item"} to="/teilnahme">Teilnahmebedingungen</NavLink></li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/products">Deals</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/shops">Shops</NavLink>
                            </li>
                            {check ?
                                this.state.usrprops !== 'gugus'
                                ? <li className="nav-item dropdown">

                                    <NavLink className={"nav-link dropdown-toggle"} to={"/"} role={"button"}
                                       data-bs-toggle="dropdown" aria-expanded="false" id={"meinkontodropdown"}>
                                        Mein Konto</NavLink>
                                    <ul className={"dropdown-menu"} aria-labelledby={"meinkontodropdown"}>
                                        <li><NavLink className="nav-link" to="/userinfo">Persönliche Daten</NavLink></li>
                                        <li><NavLink className="nav-link" to="/#">Deine Spielpools</NavLink></li>
                                        <li><NavLink className="nav-link" to="/#">Einstellungen</NavLink></li>
                                        <li>
                                            <hr className="dropdown-divider"></hr>
                                        </li>

                                        <li><NavLink className="nav-link" to="/logout">Abmelden</NavLink></li>
                                    </ul>
                                </li>
                                : <li className="nav-item">
                                    <NavLink className="nav-link" to="/login">Anmeldung</NavLink>
                                </li>
                                : <p></p>
                            }
                        </ul>

                        <form className="d-flex">
                            <input className="form-control me-2" type="search" placeholder="Search"
                                   aria-label="Search"></input>
                            <button className="btn btn-outline-success" type="submit">Suchen</button>
                        </form>
                    </div>
                </div>
            </nav>
        );
    }
}

export default withCookies(TopMenu)